.statementmobile{
    display:none;
}
.aboutContainer{
    height: 37rem;
    background: rgba(194, 197, 170, 1);
    display: flex;
    justify-content: center;
}
.webcontainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 100%;
}
.webcontainer div{
    margin: 10%;
    width: 60%;
}
.aboutContainer div .title{
    font-family: 'Libre Baskerville', serif;
}
.aboutContainer .cause{
    text-align: right;
}
.aboutContainer .statementweb{
    font-family: 'Montserrat', serif;
    font-weight: 500;
    text-align: center;
}
.desc{
    font-family: 'Montserrat', serif;
}
.amazonLink{
    color: #6c584c;
    text-decoration: none;
    font-weight: 650;
}
@media (max-width: 1100px) {
    .aboutContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .webcontainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        vertical-align: top;
        width: 100%
    }
    .statementmobile{
        font-family: 'Montserrat', serif;
        font-weight: 500;
        text-align: center;
        display: block;
        width: 100%;
        padding-bottom: 3rem;
    }
    .statementmobile p{
        margin: 0 1rem 0;
    }
    .statementweb{
        display: none;
    }
    .desc{
        font-size: 12px;
    }
}