.footer{
    margin-bottom: 0;
    background-color: rgba(226, 218, 201, 1);
    backdrop-filter: saturate(100%) blur(20px);
    color: #6c5848;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', serif;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title{
    font-weight: 600;
}
.footer .content{
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;
}
.footer .content .socials{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
    margin-left: 1rem;
}
.footer .content .socials .card p{
    margin: 1rem 0 2rem;
}
.footer .content .socials .links p{
    margin: 0;
}
.footer .content .socials .links .link{
    text-decoration: none;
    width: 0px;
    color: #6c584c; 
    transition: 0.4s;
    cursor: pointer;
}
.link::after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: #6c584c;
    transition: 300ms;
}
.link:hover::after {
    width: 100%;
}
.footer .content .socials .links .mailto{
    text-decoration: none;
    width: 0px;
    color: #6c584c; 
    transition: 0.4s;
    cursor: pointer;
}
.footer .content .socials .links .important{
    font-weight: 550;
}
.footer .content .blurb{
    margin: 0 1rem 1rem;
    width: 30%;
    text-align: right;
}
.credits{
    background-color:#f0ead2;
    font-family: 'Montserrat', serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.creditContainer{
    width: 40%;
    display: flex;
    justify-content: space-between;
}
.credits p{
    margin: 0;
    padding:1rem;
}
.imgContainer{
    display: flex;
    align-items: end;
    justify-content: right;
    padding-left: 1rem;
}
.imgContainer .img{
    width: 272px;
    height: 192px;
    margin: 0;
    opacity: 0.3;
}
@media (max-width: 1100px){
    .imgContainer{
        display: none;
    }
    .footer .content .socials{
        display: flex;
        flex-direction: column;
        justify-content: baseline;
    }
    .credits{
        display: flex;
        flex-direction: column;
        font-size: 12px;
    }
    .creditContainer{
        width: 100%;
    }
    .creditContainer p{
        padding: 1rem 1rem 0;
    }
    .link::after {
        width: 0px;
    }
    .link:hover::after {
        width: 0%;
    }
    .footer .content .socials .links p{
        margin: 0;
        font-size: 14px;
    }
    .footer .content .blurb{
        margin: 0 1rem 1rem;
        width: 30%;
        text-align: right;
        font-size: 14px;
    }
}
