.campsContainer{
    margin:0;
    padding:0;
    padding-top: 2.5rem;
    padding-bottom: 3.5rem;
    display: flex;
    flex-direction: row;
    background-color: #fefae0;
    align-items: center;
    justify-content: center;
}
.sectionHeader{
    display: flex;
    flex-direction: column;
}
.campsContainer .campsHeader .plain{
    font-family: 'Montserrat', serif;
    font-size: 55px;
}
.campsContainer .campsHeader .hide{
    font-family: 'Libre Baskerville', serif;
    font-size: 55px;
}
.campsContainer .cohort-header{
    font-family: 'Montserrat', serif;
    font-size: 20px;
    font-weight: 250;
}
.cohort-header .notifclick{
    font-size: 13px;
}
.campsContainer .campCards{
    display: flex;
    flex-direction: column;
    align-items: left; 
    justify-content: space-between;
    margin-left:5%;
    margin-right:5%;
    height: 120%;
}
.campsContainer .campCards .card{
    width: 14rem;
    height: 3rem;
    background: #6c584c;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    color: white;
    margin: 0.3rem;
    border-radius: 5px;
}
.campsContainer .campCards .card .card-title{
    font-family: 'Montserrat', serif;
    align-self: left;
    margin-left:5%;
}
.campsContainer .campCards .card .desc{
    font-family: 'Raleway', serif;
    color: #f0ead2;
    font-weight: 600;
}
/* moving to camp-info style */
.ovr .campInfo{
    margin:0;
    background-color: #e9edc9;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ovr .campInfo .specs{
    font-family: 'Montserrat', serif;
    font-size: 13px;
    margin-top: 2rem;
}
.ovr .campInfo .title{
    margin-top: 2rem;
    font-family: 'Libre Baskerville', serif;
    font-size: 30px;
    margin: 0;
    margin-bottom: 1rem;
}
.ovr .campInfo .info-container{
    width: 60%;
    display: flex;
}
.ovr .campInfo .info-container .info-width{
    width: 100%;
}
.ovr .campInfo .info-container .info-width .info{
    margin: 0;
    margin-bottom: 3rem;
    font-family: 'Montserrat', serif;
    font-size: 18px;
    text-align: center;
}
@media (max-width: 1100px) {
    .campsContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .campsContainer .campsHeader{
        text-align: center;
    }
    .campsContainer .campsHeader .plain{
        font-size: 30px;
    }
    .campsContainer .campsHeader .hide{
        font-size: 30px;
    }
    .campsContainer .cohort-header{
        font-size: 15px;
        text-align: center;
    }
    .campsContainer .campCards{
        display: flex;
        flex-direction: column;
        align-items: left; 
        justify-content: space-between;
    }
    .campsContainer .campCards .card{
        width: 20vh;
        background: #6c584c;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        text-decoration: none;
        color: white;
    }
    .campsContainer .campCards .card .card-title{
        font-family: 'Montserrat', serif;
        font-size: 13px;
        margin-left: 5%;
        margin-bottom: 1rem;
        padding-bottom: 0;
    }
    .ovr .campInfo .title{
        margin-top:1rem;
    }
    .ovr .campInfo .info-container .info-width .info{
        margin: 0;
        margin-bottom: 3rem;
        font-family: 'Montserrat', serif;
        font-size: 12px;
        text-align: center;
    }
}

.fade-letter {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.1s ease; /* Reduce the speed of the fade by changing the 0.1 to a higher number */
}